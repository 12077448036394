<template>
  <main>
    <div class="container">
      <div class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                  class="bg-primary style_level d-flex justify-content-center align-items-center"
                >
                  <h1 v-if="estPeuOuPasInstruit" class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                  <h1 v-else class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                </div>
              </div>
              <div class="text-center mb-4">
                <h4 class="text-primary">Inscription en tant que candidat(e)</h4>
                <h6 class="text-muted" v-if="estPeuOuPasInstruit">
                  Etape 1/{{ steps[currentStep].title }}
                </h6>
                <h6 class="text-muted" v-else>
                  Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }}
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="demandeur"
                  v-if="index == currentStep"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>
              <div v-if="!(!isLastStep && !estPeuOuPasInstruit)" class="row">
                <politique-confidentialite-dialog ref="policyDialog" />
                <div class="col-md-12 col-sm-12 border border-primary d-flex py-2">
                  <b-checkbox
                    name="policyAccepted"
                    class="form-contr me-3"
                    v-model="policyAccepted"
                    :binary="true"
                  >
                    <span class="ms-3">J'accepte la politique de confidentialité.</span>
                  </b-checkbox>
                  <a href="#" class="text-primary" @click.prevent.stop="showPolicyDialog"
                    >Voir la politique</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep && !estPeuOuPasInstruit"
                      @click.prevent="nextStep"
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Suivant
                    </button>
                    <button
                      @click.prevent="submitForm"
                      v-else
                      type="submit"
                      :disabled="busy"
                      class="btn btn-primary btn-lg"
                    >
                      <span
                        v-if="busy"
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <h6 class="text-muted text-center">
                J'ai un compte candidat
                <a href="connexion.html" class="text-primary">connectez-vous</a>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Adresse from "../../components/register/demandeur/steps/Adresse.vue";
import Civilite from "../../components/register/demandeur/steps/Civilite.vue";
import NiveauInstruction from "../../components/register/demandeur/steps/NiveauInstruction.vue";
import Identifiant from "../../components/register/demandeur/steps/Identifiant.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions } from "vuex";
import PolitiqueConfidentialiteDialog from "../../components/confidencialite/PolitiqueConfidentialiteDialog.vue";
// import DisponibiliteActuelle from '../../components/register/demandeur/steps/DisponibiliteActuelle.vue'

export default {
  components: { PolitiqueConfidentialiteDialog },
  data() {
    return {
      currentStep: 0,
      busy: false,
      policyAccepted: false,
      demandeur: {
        nom: null,
        prenom: null,
        sexe: null,
        email: null,
        adresse: null,
        lieuNaissance: null,
        paysDeResidence: null,
        niveauInstruction: null,
        disponible: null,
        souscontrat: null,
        dejaInscrit: null,
        paysDeResidence: null,
        npi: null,
        ifu: null,
        metierDesire: null,
        metierAppris: null,
        metierFormation: null,
        metierApprisDescription: null,
        metierDesireDescription: null,
        metierQualification: null,
        metierFormationDescription: null,
        metierQualificationDescription: null,
        azoli: {},
        estInstruit: null,
        dateNais: null,
        codePostal: null,
        password: null,
        password1: null,
        commune: null,
        departement: null,
        arrondissement: null,
        ville: null,
        phone: null,
      },
      steps: [
        // {
        //   icon: 'fa fa-home',
        //   name: 'first',
        //   title: 'Disponibitlité actuelle',
        //   subtitle: 'Subtitle sample',
        //   component: DisponibiliteActuelle,
        // },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Niveau d'instruction",
          subtitle: "Subtitle sample",
          component: NiveauInstruction,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Etat civil",
          subtitle: "Subtitle sample",
          component: Civilite,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Adresse",
          subtitle: "Subtitle sample",
          component: Adresse,
        },
        {
          icon: "fa fa-money-bill-alt",
          name: "third",
          title: "Identifiant",
          subtitle: "Booking adresse",
          component: Identifiant,
          hideFooter: true,
          hiddeHeader: false,
        },
      ],
    };
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
  },
  watch: {
    demandeur() {
      localStorage.setItem("subscription", JSON.stringify(this.demandeur));
    },
    currentStep() {
      localStorage.setItem("currentStep", this.currentStep);
    },
  },
  computed: {
    totalSteps() {
      return this.steps.length;
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
    estPeuOuPasInstruit() {
      return (
        this.demandeur.estInstruit != null &&
        (!this.demandeur.estInstruit ||
          (this.demandeur.estInstruit &&
            this.demandeur.niveauInstruction != null &&
            this.demandeur.niveauInstruction <= 10))
      );
    },
    hasValidEmail() {
      return this.demandeur.email != null;
    },
  },
  methods: {
    ...mapActions({
      registerDemandeur: "account/registerDemandeur",
    }),
    showPolicyDialog() {
      this.$refs.policyDialog.show();
    },
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    async submitForm() {
      if (!this.policyAccepted) {
        this.$toast.error(
          "Vous devez accepter la politique de confidentialité avant de continuer!",
          {
            position: "bottom-right",
            duration: 8000,
          }
        );
        return;
      }

      if (!(await this.validateStep(this.currentStep))) {
        this.$toast.error("Certains champs du formulaire semblent être invalides.", {
          position: "top-right",
          duration: 8000,
        });
        return;
      }

      this.busy = true;
      this.registerDemandeur(this.demandeur)
        .then(() => {
          this.busy = false;
          this.$router.push({
            name: "accounts.registration.successful",
            query: { ppi: this.estPeuOuPasInstruit, hasEmail: this.hasValidEmail },
          });
        })
        .catch(() => {
          this.busy = false;
        });
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },
    async validateStep(step) {
      console.log(step);
      console.log(this.$refs[`currentTab_${step}`][0]);
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
      // return this.$refs[`currentTab_${step}`][0].do()
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
